import { combineReducers } from 'redux';

import userReducer from './userReducer';
import questionReducer from './questionReducer';
import examReducer from './examReducer';
import dataReducer from './shareDataReducer';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import successReducer from './successReducer';
import themeReducer from './themeReducer';
import resultReducer from './resultReducer';
import categoryReducer from './categoryReducer';
import subscriptionReducer from './subscriptionReducer';

export const reducers = combineReducers({error: errorReducer,data:dataReducer, success: successReducer ,theme:themeReducer,subscription:subscriptionReducer,question:questionReducer,auth:authReducer,user:userReducer,exam:examReducer,result:resultReducer,category:categoryReducer});