import { START_LOADING, END_LOADING,CALCULATE_RESULT,FETCH_RESULT_BY_USER_ID, CREATE_RESULT, UPDATE_RESULT, DELETE_RESULT, RESULT, FETCH, FETCH_BY_ID} from '../constants/actionTypes';

const initialState = {
    result:[],
    results:[],
    newResult:[],
    submittedResult:[],
    isLoading: false,
};

const resultReducer = (state = initialState,action) =>{
    if(action?.payload?.type === RESULT ){
        switch (action.type){
            case START_LOADING:
                return {...state, isLoading:true}
            case END_LOADING:
                return {...state, isLoading:false}
            case CALCULATE_RESULT:
                return {...state, result: action.payload};
            case "RESULT":
                return {...state, submittedResult: action.payload.data};
            case "CALCULATE_MARK":
                return {...state, results: action.payload.data};
            case FETCH:
                return {...state, results: action.payload.data};  
            case FETCH_BY_ID:
                return {...state, results: action.payload.data};  
            case FETCH_RESULT_BY_USER_ID:
                return {...state, results: action.payload.data};           
            case CREATE_RESULT:
                return { ...state, newResult:action.payload};
            case UPDATE_RESULT:
                return {...state, results: state.result.map((result)=> (result._id === action.payload._id?action.payload:result))};
            case DELETE_RESULT:
                return {...state, results: state.results.map((result) => result._id !== action.payload.data._id) };
            default:
                return state;

            }
        }
        else{
            return state;
        }}
export default resultReducer;