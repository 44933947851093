import { START_LOADING, END_LOADING, FETCH_RANDOM_QUESTION, CREATE, UPDATE, DELETE, QUESTION, FETCH, FETCH_ALL, FETCH_BY_ID} from '../constants/actionTypes';

const initialState = {
    question:[],
    isLoading: true,
};

const questionReducer = (state = initialState,action) =>{
    if(action?.payload?.type === QUESTION ){
        switch (action.type){
            case START_LOADING:
                return {...state, isLoading:true}
            case END_LOADING:
                return {...state, isLoading:false}
            case FETCH:
                return {...state, question: action.payload};
            case FETCH_BY_ID:
                return {...state, question: action.payload};
            case FETCH_ALL:
                return {...state, question: action.payload};
            case FETCH_RANDOM_QUESTION:
                return {...state, question: action.payload};            
            case CREATE:
                return { ...state, question: [...state.question, action.payload.data] };
            case UPDATE:
                return {...state, question: state.question.map((question)=> (question._id === action.payload._id?action.payload:question))};
            case DELETE:
                return {...state, question: state.question.map((question) => question._id !== action.payload) };
            default:
                return state;
        }
    }
    else{
        return state;
    }}
export default questionReducer;