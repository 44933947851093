export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE';

export const EXAM = 'exam';
export const USER = 'user';
export const free = 'free';
export const paid = 'paid';
export const RESULT = 'result';
export const CALCULATE='calculate'
export const QUESTION = 'question';
export const CATEGORY = 'category';
export const SUBSCRIPTION = 'subscription';
export const CALCULATE_AND_SAVE='calculate_and_save'

export const reject_user_Subscription = 'reject_user_Subscription';
export const expire_user_Subscription = 'expire_user_Subscription';
export const update_user_Subscription = 'update_user_Subscription';
export const approve_user_Subscription = 'approve_user_Subscription';
export const approve_user_update_subscription = 'approve_user_update_subscription';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const ADD = 'ADD';
export const EDIT = 'EDIT';
export const REMOVE = 'REMOVE';
export const SHOW = 'SHOW';

export const SET_ERROR = 'SET_ERROR'
export const GET_ERROR = 'GET_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const CLEAR = 'CLEAR'

export const SET_SUCCESS = 'SET_SUCCESS'
export const GET_SUCCESS = 'GET_SUCCESS'
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS'

export const FETCH  = 'FETCH';
export const FETCH_FREE  = 'FETCH_FREE';
export const FETCH_PAID  = 'FETCH_PAID';
export const FETCH_FULL_INFO = 'FETCH_FULL_INFO';
export const FETCH_FULL_INFO_BY_ID = 'FETCH_FULL_INFO_BY_ID';
export const FETCH_BY_ID  = 'FETCH_BY_ID';
export const CREATE = 'CREATE';
export const REJECT = 'REJECT';
export const EXPIRE = 'EXPIRE';
export const APPROVE = 'APPROVE';
export const UPDATE = 'UPDATE';
export const ACTION = 'ACTION';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const DELETE_ALL = 'DELETE_ALL';


export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_ALL_USER = 'FETCH_ALL_USER';
export const UPDATE_PROFILE_PIC = 'UPDATE_PROFILE_PIC';
export const UPDATE_USER_SUBSCRIPTION = 'UPDATE_USER_SUBSCRIPTION/action=update';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';


export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';
export const FETCH_SUBSCRIPTION = 'FETCH_SUBSCRIPTION';
export const FETCH_SUBSCRIPTIONS = 'FETCH_SUBSCRIPTIONS';

export const CREATE_QUESTION = 'CREATE_QUESTION';
export const UPDATE_QUESTION = 'UPDATE_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const FETCH_ALL_QUESTION = 'FETCH_ALL_QUESTION';
export const FETCH_RANDOM_QUESTION = 'FETCH_RANDOM_QUESTION';
export const FETCH_QUESTION = 'FETCH_QUESTION';


///// Exam /////
export const FETCH_EXAM = 'FETCH_EXAM';
export const UPDATE_EXAM = 'UPDATE_EXAM';
export const DELETE_EXAM = 'DELETE_EXAM';
export const CREATE_EXAM = 'CREATE_EXAM';

///// Result /////
export const FETCH_RESULT = 'FETCH_RESULT';
export const UPDATE_RESULT = 'UPDATE_RESULT';
export const DELETE_RESULT = 'DELETE_RESULT';
export const CALCULATE_RESULT = 'CALCULATE_RESULT';
export const CREATE_RESULT = 'CREATE_RESULT';
export const FETCH_RESULT_BY_USER_ID = 'FETCH_RESULT_BY_USER_ID';




export const FETCH_POST = 'FETCH_POST';
export const LIKE = 'LIKE';
export const COMMENT = 'COMMENT';
export const FETCH_BY_CREATOR = 'FETCH_BY_CREATOR';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';

export const END_LOADING = 'END_LOADING';
export const START_LOADING = 'START_LOADING';

export const SET_TIMEOUT_FLAG = 'SET_TIMEOUT_FLAG';