import { SET_ERROR, CLEAR_ERROR, GET_ERROR } from '../constants/actionTypes';

const initialState = {
  error: null,
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ERROR:
      return {
        ...state,
        error: state?.error,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export default errorReducer;