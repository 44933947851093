import { Suspense, lazy } from 'react';
import SuspenseLoader from './SuspenseLoader/index';
import SidebarLayout from './pages/global/sidebarLayout';
const Loader = (Component) => (props) => ( <Suspense fallback={<SuspenseLoader />}> <Component {...props} /> </Suspense> );
// ADMIN
const PaidExam = Loader(lazy(() => import('./pages/admin/Exam/PaidExam')));
const FreeExam = Loader(lazy(() => import('./pages/admin/Exam/FreeExam')));
const ManageUser = Loader(lazy(() => import('./pages/admin/User/UserList')));
const AdminDashboard = Loader(lazy(() => import('./pages/admin/Dashboard')));
const Category = Loader(lazy(() => import('./pages/admin/Category/Category')));
const EditUser = Loader(lazy(() => import('./pages/admin/User/editProfile/index')));
const AddQuestion = Loader(lazy(() => import('./pages/admin/Question/Question/index')));
const Subscription = Loader(lazy(() => import('./pages/admin/Subscription/Subscription')));

//User
 const MainLayout = Loader(lazy(() => import('./pages/global/mainLayout')));
 const NavbarLayout = Loader(lazy(() => import('./pages/global/mainLayout/nav')));

const FAQ = Loader(lazy(() => import('./pages/home/FAQ')));
const Home = Loader(lazy(() => import('./pages/home/Home')));
const ExamList = Loader(lazy(() => import('./pages/exam/ExamList')));
const StartExam = Loader(lazy(() => import('./pages/exam/startExam')));
const ResultPage = Loader(lazy(() => import('./pages/exam/resultPage')));
const Login = Loader(lazy(() => import('./pages/auth/loginPages/index')));
const ContactUs = Loader(lazy(() => import('./pages/Contact/Contactus')));
const Register = Loader(lazy(() => import('./pages/auth/signupPages/index')));
const StartFreeExam = Loader(lazy(() => import('./pages/exam/StartFreeExam')));
const Profile = Loader(lazy(() => import('./pages/exam/user/editProfile/index')));
const UpdateSubscription = Loader(lazy(() => import('./pages/auth/Subscription')));
const ResetPassword = Loader(lazy(() => import('./pages/auth/resetPassword/index')));
const ForgotPassword = Loader(lazy(() => import('./pages/auth/forgotPassword/index')));
const UserDashboard = Loader(lazy(() => import('./pages/exam/user/Dashboard/dashboard')));
const PageNotFound = Loader(lazy(() => import('./pages/global/ErrorPage/404PageNotFound')));
const AdditionalSubscription = Loader(lazy(() => import('./pages/auth/Subscription/AdditionalSubscription')));
const ResultDetail = Loader(lazy(() => import('./pages/exam/user/Dashboard/dashboard/ResultDetail')));

const routes =  [
  {
    path: '',
    element: <Home />
  },
  {
    path: 'admin/dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <AdminDashboard />
      },
      {
        path: 'mains',
        element: <Home />
      },
      {
        path: 'question',
        element: <AddQuestion />
      },
      {
        path: 'paid-exam',
        element: <PaidExam />
      },
      {
        path: 'free-exam',
        element: <FreeExam />
      },
      {
        path: 'user',
        element: <ManageUser />
      },
      {
        path: 'user/edit-user/:userId',
        element: <EditUser />
      },
      {
        path: 'category',
        element: <Category />
      },
      {
        path: 'subscription',
        element: <Subscription />
      },
      {
        path: '*',
        element: <PageNotFound />
      }
      
    ]
  },
  {
    path: 'user',
    element: <MainLayout/>,
    children: [
      {
        path: 'dashboard',
        element: <UserDashboard/>
      },
      {
        path: 'result-summary',
        element: <ResultDetail />
      },
      {
        path: 'page/profile',
        element: <Profile />
      },
      {
        path: 'new/subscription',
        element: <AdditionalSubscription />
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  },

  {
    path: 'exam',
    children: [
      {
        path: 'result',
        element: <ResultPage/>
      },
      {
        path: 'start',
        element: <StartExam/>
      },
      {
        path: 'free',
        element: <NavbarLayout isQuickLinkExcluded={false}/>,
        children: [
          {
            path: '',
            element: <ExamList/>
          },
          {
            path: 'start',
            element: <StartFreeExam/>
          },
          {
            path: '*',
            element: <PageNotFound />
          }
        ]
      },
      {
        path: '',
        element: <PageNotFound />
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  },
  {
    path: '',
    element: <NavbarLayout/>,
    children: [
      {
        path: 'faq',
        element: <FAQ />
      },
      {
        path: 'contact',
        element: <ContactUs />
      }
    ]
  },
  {
    path: 'auth',
    element: <NavbarLayout/>,
    children: [
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'register',
        element: <Register />
      },
      {
        path: 'password/forgot-password',
        element: <ForgotPassword />
      },
      {
        path: 'password/reset-password/:resetToken',
        element: <ResetPassword />
      },
      {
        path: 'update/subscription/:categoryId',
        element: <UpdateSubscription />
      },
      {
        path: '',
        element: <PageNotFound />
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  },
  {
    path: '',
    element: <NavbarLayout isQuickLinkExcluded={false}/>,
    children: [
     { 
      path: '*',
      element: <PageNotFound />
    }
    ]

  }
];
export default routes;