import { START_LOADING,SET_TIMEOUT_FLAG , END_LOADING, FETCH_ALL, CREATE, UPDATE, DELETE, EXAM, FETCH, GET_ERROR, CLEAR_ERROR, SET_ERROR, FETCH_BY_ID, FETCH_FREE, FETCH_PAID} from '../constants/actionTypes';

const initialState = {
    exam:[],
    exams:[],
    freeExams:[],
    paidExams:[],
    isLoading: false,
    isTimeout: false,
    isTimeReset: false,
    error: null
};

const examReducer = (state = initialState,action) =>{
    if(action?.payload?.type === EXAM ){
        switch (action.type){
            case GET_ERROR:
                return {
                ...state,
                error: null
                };
            case CLEAR_ERROR:
                return {
                ...state,
                error: null
                };
            case SET_ERROR:
                return {
                ...state,
                error: action.payload.error
                };
            case START_LOADING:
                return {...state, isLoading:true}
            case END_LOADING:
                return {...state, isLoading:false}
            case FETCH:
                return {...state, exam: action.payload.data};
            case FETCH_FREE:
                return {...state, freeExams: action.payload.data};
            case FETCH_PAID:
                return {...state, paidExams: action.payload.data};
            case FETCH_BY_ID:
                return {...state, exam: action.payload.data};
            case FETCH_ALL:
                return {...state, exam: action.payload.data};
            case 'FETCH_FREE_EXAM':
                return {...state, exam: action.payload};  
            case 'FETCH_EXAM_BY_CATEGORY':
                return {...state, exams: action.payload}; 
            case 'FETCH_EXAM_BY_RESULT':
                return {...state, exam: action.payload}; 
            case 'FETCH_EXAM_BY_CATEGORY_FROM_BODY':
                return {...state, exams: action.payload};             
            case CREATE:
                return { ...state, exam: [...state.exam, action.payload] };
            case UPDATE:
                return {...state, user: action.payload.data};
            case DELETE:
                return {...state, exam: state.exam.map((exam) => exam._id !== action.payload) };
            case SET_TIMEOUT_FLAG:
                return { ...state, isTimeout: action.payload.data };
            case "RESET_TIME":
                return { ...state, isTimeReset: action.payload };
            default:
                return state;
        }
    }
    else{
        return state;
    }}
export default examReducer;