import { START_LOADING, END_LOADING, FETCH_ALL, FETCH, CREATE, UPDATE, DELETE, CATEGORY, FETCH_BY_ID} from '../constants/actionTypes';

const initialState = {
    category:[],
    actionCategory:[],
    selectedCategory:[],
    categoryTest:[],
    isLoading: false,
};


const categoryReducer = (state = initialState, action) => {
    if(action?.payload?.type === CATEGORY ){
        switch (action.type) {
          case START_LOADING:
            return { ...state, isLoading: true };
          case END_LOADING:
            return { ...state, isLoading: false };
          case FETCH:
            return { ...state, category: action.payload.data };
          case FETCH_BY_ID:
            return { ...state, selectedCategory: action.payload.data };
          case FETCH_ALL:
            return { ...state, category: action.payload.data };
          case CREATE:
            return { ...state, actionCategory: [...state.category, action.payload] };
          case UPDATE:
            return { ...state, categoryTest: action.payload };
          case DELETE:
            return {
              ...state,
              actionCategory: state.category.filter((category) => category._id !== action.payload),
            };
          default:
            return state;
        }
    }
    else{
        return state;
    }
  };


export default categoryReducer;