import { useRoutes } from 'react-router-dom';
import routes from './routes';
import { CssBaseline } from "@mui/material";
import MUITheme from './utils/theme'
import { SnackbarProvider } from 'notistack'

function App() {
  const content =  useRoutes(routes);

  return (
    <MUITheme>
      <SnackbarProvider>
        <CssBaseline />
        {content}
      </SnackbarProvider>
    </MUITheme>
  );
}
export default App;