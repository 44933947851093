const initialState = {
    themeConfig: {
      mode: 'light',
      stickyHeader: true,
      pageTransitions: false,
      fontFamily: 'Rubik',
      borderRadius: 2,
    },
  };
  const THEME_CONFIG_KEY = 'THEME_DATA';
  
  const getInitialState = () => {
    const localStorageData = localStorage.getItem(THEME_CONFIG_KEY);
    if (localStorageData) {
      return { themeConfig: JSON.parse(localStorageData) };
    }
    return initialState;
  };
  
  const themeReducer = (state = getInitialState(), action) => {
    switch (action.type) {
      case 'SET_DEFAULT_CONFIG':
        return { ...state, themeConfig: initialState.themeConfig };
      case 'SET_CONFIG':
        return { ...state, themeConfig: action.payload };
      case 'SET_CONFIG_KEY':
        return {
          ...state,
          themeConfig: {
            ...state.themeConfig,
            [action.payload.key]: action.payload.value,
          },
        };
      default:
        return state;
    }
  };
  
  export default themeReducer;