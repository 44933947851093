import { useContext } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { ColorModeContext } from "../../theme";
import { MenuTwoTone } from "@mui/icons-material";
import { useDispatch } from 'react-redux';
import { SidebarContext } from './contexts/SidebarContext';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Box, Grid, IconButton, Tooltip, useTheme } from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { logout } from "../../action/auth";


const NavBar = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  const Main = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(0),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('lg')]: {
          marginLeft: `${theme.sidebar.width}`
        },
      }),
    }),
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
		dispatch(logout(navigate));
	};

  return (
    <Main open={sidebarToggle} position="sticky"  sx={{backgroundColor:'customPrimary.main',display:'flex'}} elevation={1} >
      <Grid container>
        <Grid item xs={6} md={3}> 
          <Box component="span" sx={{ ml: 2, display:'inline-block'}}>
            {!sidebarToggle ? (
              <Tooltip arrow title="Open Menu">
                  <IconButton  color="primary" onClick={toggleSidebar}>
                      <MenuTwoTone fontSize="small" />
                  </IconButton>
              </Tooltip>
              ) : (
                null
              )}
          </Box>
        </Grid>
        <Grid item xs={6} md={9} sx={{justifyContent:"flex-end", display:'flex',pr:2}}>
          <Box display="flex">
            <IconButton onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
            </IconButton>
            <IconButton>
              <NotificationsOutlinedIcon />
            </IconButton>
            <IconButton>
              <SettingsOutlinedIcon />
            </IconButton>
            <IconButton onClick = {() => {handleLogout()}}>
              <PersonOutlinedIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      </Main>
  );
};
export default NavBar;