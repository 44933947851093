import { SET_SUCCESS, GET_SUCCESS, CLEAR_SUCCESS } from '../constants/actionTypes';

const initialState = {
  success: null,
};

const successReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUCCESS:
      return {
        ...state,
        success: action.payload,
      };
    case GET_SUCCESS:
      return {
        ...state,
        success: state?.success,
      };
    case CLEAR_SUCCESS:
      return {
        ...state,
        success: null,
      };
    default:
      return state;
  }
};

export default successReducer;