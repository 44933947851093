import { useState, createContext } from 'react';

export const idContext = createContext();

export const IdProvider = ({ children }) => {
  const [id, setId] = useState('');

  return (
    <idContext.Provider value={{ id, setId}}>
      {children}
    </idContext.Provider>
  );
};
